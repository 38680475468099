.wrapper {
  position: relative;
}

.selector {
  height: 40px;
  display: flex;
  align-items: center;
  background: transparent;
  justify-content: space-between;
  padding: 0;
  border: 0;
}

.selector:focus {
  outline: none;
}

.selector:hover {
  cursor: pointer;
}

.valueWrapper {
  display: flex;
  margin-right: 19px;
}

.value {
  color: var(--placeholder-gray);
  font-size: 13px;
}

.icon {
  margin-right: 8px;
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileSelect {
  display: none;
}

.dropdown-list {
  top: -110px !important;
  right: 0;
}

@media (max-width: 559px) {
  .mobileSelect {
    opacity: 0;
    display: block;
    position: absolute;
    z-index: 3000;
    top: 0;
    left: 0;
    width: 100%;
    height: 45px;
  }

  .selector {
    pointer-events: none;
  }
}
