/**/

.container {
    vertical-align: middle;
    display: flex;
    align-items: center;
    width: fit-content;
}

.container:hover {
    cursor: pointer;
}

.checkbox {
    border: 1px solid rgb(219, 222, 222);
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 3px;
    transition: all 150ms;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkbox:hover {
    cursor: pointer;
}

.checkboxLarge {
    width: 24px;
    height: 24px;
}

.checkedLarge {
    border: 1px solid var(--dark-blue);
}

.largeCheck {
    width: 14px;
}

.checkboxHidden {
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.text {
    margin-left: 14px;
    font-size: 13px;
    color: var(--placeholder-gray);
}

.textLarge {
    margin-left: 10px;
}

.disabledLargeCheckbox {
    border: 1px solid var(--light-gray);
    background-color: transparent;
}

.disabledLargeText {
    color: var(--light-gray);
}
