.wrapper {
    min-height: 100vh;
    width: 100%;
    display: flex;
}

.staticSection {
    flex: 1;
}

.logoWrapper {
    margin-right: 350px;
    margin-top: 31px;
    margin-left: auto;
    width: 162px;
    margin-bottom: 40px;
}

.imageWrapper {
    width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.imageWrapper > img {
    max-width: 100%;
}

.loginSection {
    flex: 1;
    background: var(--black);
    padding-bottom: 130px;
}


.loginWrapper {
    margin-top: 37px;
    margin-left: 158px;
    width: 355px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.langPickerWrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 115px;
    align-self: flex-end;
}

@media(min-width: 1050px) and (max-width: 1342px) {
    .loginWrapper {
        margin-right: auto;
        margin-left: auto;
    }

    .logoWrapper {
        width: 355px;
        margin-left: auto;
        margin-right: auto;
    }
}

.mobileLogoWrapper {
    display: none;
}

@media(max-width: 1049px) {

    .staticSection {
        display: none;
    }

    .loginWrapper {
        margin-top: 16px;
        margin-left: auto;
        margin-right: auto;
    }

    .mobileLogoWrapper {
        display: block;
        width: 136px;
        margin-top: 3px;
    }


    .langPickerWrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        width: 100%;
    }

    .langPickerWrapper > * {
        flex: 1;
    }

    .langPicker > *:first-child {
        margin-left: auto;
        padding-right: 0;
        padding-left: 0;
    }

    .langPicker {
        width: 60px;
        /*margin-left: 30px;*/
        margin-right: -10px !important;
    }

    .loginSection {
        padding-bottom: 80px;
    }

    .flexKeeper, .langPickerWrapper {
        flex: 1;
    }
    .mobileLogoWrapper {
        flex: 2;
        text-align: center;
        margin-right: 0;
    }
    .mobileLogoWrapper > img {
        max-width: 134px;
        margin: 0;
    }

    .langPicker {
        margin: 0;
    }
}

.flexKeeper {
    opacity: 0;
}


@media(max-width: 374px) {
    .loginWrapper {
        max-width: 100%;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .loginSection {
        padding-bottom: 80px;
    }

    .flexKeeper, .langPickerWrapper {
        flex: 1;
    }
    .mobileLogoWrapper {
        flex: 2;
        text-align: center;
        margin-right: 0;
    }
    .mobileLogoWrapper > img {
        max-width: 134px;
        margin: 0;
    }

    .langPicker {
        margin: 0;
    }
}
